


























































































































































import Vue from "vue";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import { ApiException, UserAccountVerifyCommand } from "@/clients/clients";
import AppLoading from "@/components/layout/AppLoading.vue";

setInteractionMode("eager");
const verifyValidationMessage =
  "You must include either a valid post code or your professional code.";

extend("required", {
  ...required,
  message: verifyValidationMessage,
});

export default Vue.extend({
  name: "ProfileEmailInformationView",
  components: {
    AppLoading,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    form: new UserAccountVerifyCommand(),
    errorMessage: "",
    updated: false,
    loading: false,
  }),
  computed: {
    ...mapGetters("UserStore", ["user", "verified"]),
  },
  created() {
    if (this.user) {
      const data = new UserAccountVerifyCommand();
      data.init(this.user);
      this.form = Object.assign({}, this.form, data);
    }
  },
  methods: {
    ...mapActions("UserStore", ["verifyAccount"]),
    async onSubmit(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.verifyAccount(this.form);
        this.updated = true;
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
